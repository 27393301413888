<template>
  <div>
    <h2 class="mb-5">A Kinetic Study - Catalase: Data & Calculations - Part A</h2>

    <h3 class="mb-4">Data</h3>

    <p class="mb-n1">
      <v-text-field
        v-model="inputs.table1Caption"
        prefix="Table 1. "
        label="Enter table caption here"
      />
    </p>

    <p class="pl-n8 mb-5">
      <v-simple-table>
        <thead>
          <tr>
            <td style="font-weight: bold; text-align: left; width: 30%">Property</td>
            <td style="font-weight: bold; text-align: center; width: 18%">Trial 1</td>
            <td style="font-weight: bold; text-align: center; width: 18%">Trial 2</td>
            <td style="font-weight: bold; text-align: center; width: 18%">Trial 3</td>
            <td style="font-weight: bold; text-align: center">Unit</td>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td>V (catalase)</td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.vCatalaseAT1"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>

            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.vCatalaseAT2"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>

            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.vCatalaseAT3"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>

            <td class="py-2 px-2 mx-0 centered-input">
              <v-select v-model="inputs.unitCatalaseA" :items="unitOptions" outlined hide-details>
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
              </v-select>
            </td>
          </tr>

          <tr>
            <td>
              V
              <chemical-latex content="(H2O2)" />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.vH2O2AT1"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>

            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.vH2O2AT2"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>

            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.vH2O2AT3"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <v-select v-model="inputs.unitH2O2A" :items="unitOptions" outlined hide-details>
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
              </v-select>
            </td>
          </tr>

          <tr>
            <td>
              V
              <chemical-latex content="(H2O)" />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.vH2OAT1"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>

            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.vH2OAT2"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>

            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.vH2OAT3"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <v-select v-model="inputs.unitH2OA" :items="unitOptions" outlined hide-details>
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
              </v-select>
            </td>
          </tr>

          <tr>
            <td>
              V
              <chemical-latex content="(total)" />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.vTotAT1"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>

            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.vTotAT2"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>

            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.vTotAT3"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <v-select v-model="inputs.unitVtotA" :items="unitOptions" outlined hide-details>
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
              </v-select>
            </td>
          </tr>

          <tr>
            <td>
              <chemical-latex content="\%~H2O2" />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.pctH2O2AT1"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>

            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.pctH2O2AT2"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>

            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.pctH2O2AT3"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0" style="text-align: center">
              <stemble-latex content="$\%$" />
            </td>
          </tr>

          <tr>
            <td>Initial Rate</td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.initRateAT1"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>

            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.initRateAT2"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>

            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.initRateAT3"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <v-select v-model="inputs.unitInitRateA" :items="unitOptions" outlined hide-details>
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
              </v-select>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </p>

    <h3 class="mb-4">Calculations</h3>

    <p class="mb-n1">
      <v-text-field
        v-model="inputs.table2Caption"
        prefix="Table 2. "
        label="Enter table caption here"
      />
    </p>

    <p class="pl-n8 mb-5">
      <v-simple-table>
        <thead>
          <tr>
            <td style="font-weight: bold; text-align: left; width: 30%">Result</td>
            <td style="font-weight: bold; text-align: center; width: 18%">Trial 1</td>
            <td style="font-weight: bold; text-align: center; width: 18%">Trial 2</td>
            <td style="font-weight: bold; text-align: center; width: 18%">Trial 3</td>
            <td style="font-weight: bold; text-align: center">Unit</td>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td>
              <chemical-latex content="[H2O2]" />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.concH2O2DT1"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>

            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.concH2O2DT2"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>

            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.concH2O2DT3"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>

            <td class="py-2 px-2 mx-0 centered-input">
              <v-select v-model="inputs.unitConcH2O2D" :items="unitOptions" outlined hide-details>
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
              </v-select>
            </td>
          </tr>

          <tr>
            <td>Initial Rate</td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.initRateAT1"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>

            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.initRateAT2"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>

            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.initRateAT3"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <v-select v-model="inputs.unitInitRateA" :items="unitOptions" outlined hide-details>
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
              </v-select>
            </td>
          </tr>

          <tr>
            <td>
              <chemical-latex content="\text{ln}[H2O2]" />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.lnH2O2DT1"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>

            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.lnH2O2DT2"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>

            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.lnH2O2DT3"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0" style="text-align: center">
              <stemble-latex content="$\text{N/A}$" />
            </td>
          </tr>

          <tr>
            <td>ln(Initial Rate)</td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.lnInitRateDT1"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>

            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.lnInitRateDT2"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>

            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.lnInitRateDT3"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0" style="text-align: center">
              <stemble-latex content="$\text{N/A}$" />
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </p>

    <p class="mb-3">
      Please upload a single pdf file containing your graph for this part of the experiment using
      the input field below. In the text field below the upload field, please provide an appropriate
      title for the graph.
    </p>

    <p class="mb-n2">
      <v-file-input v-model="attachments" multiple accept="application/pdf" />
    </p>

    <p class="mb-3">
      <v-text-field v-model="inputs.graphPartATitle" label="Enter title here" />
    </p>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import STextarea from '@/common/components/STextarea.vue';
import CalculationInput from '@/tasks/components/inputs/CalculationInput.vue';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';

export default {
  name: 'OttawaLab5ReportSheetPartA',
  components: {
    ChemicalLatex,
    CalculationInput,
    StembleLatex,
    STextarea,
  },
  mixins: [DynamicQuestionMixin()],
  props: {
    isSubmitting: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        table1Caption: null,
        vCatalaseAT1: null,
        vCatalaseAT2: null,
        vCatalaseAT3: null,
        vH2O2AT1: null,
        vH2O2AT2: null,
        vH2O2AT3: null,
        vH2OAT1: null,
        vH2OAT2: null,
        vH2OAT3: null,
        vTotAT1: null,
        vTotAT2: null,
        vTotAT3: null,
        pctH2O2AT1: null,
        pctH2O2AT2: null,
        pctH2O2AT3: null,
        initRateAT1: null,
        initRateAT2: null,
        initRateAT3: null,
        unitCatalaseA: null,
        unitH2O2A: null,
        unitH2OA: null,
        unitVtotA: null,
        unitInitRateA: null,

        table2Caption: null,
        concH2O2DT1: null,
        concH2O2DT2: null,
        concH2O2DT3: null,
        lnH2O2DT1: null,
        lnH2O2DT2: null,
        lnH2O2DT3: null,
        lnInitRateDT1: null,
        lnInitRateDT2: null,
        lnInitRateDT3: null,
        unitConcH2O2D: null,

        graphPartATitle: null,
      },
      unitOptions: [
        '°C',
        'g',
        'g/L',
        'mL/drop',
        'kg',
        'mL',
        'mol/L',
        'kPa/s',
        '°F',
        'g/mL',
        'K',
        'mol/kg',
        'm/s',
        'min',
        'M/min',
      ],
      attachments: [],
    };
  },
};
</script>

<style></style>
